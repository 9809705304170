<template>
    <div class="center">
        <div class="banner">
            <a href="/home/Store/index/store_id/3.html" class="img">
                <div class="ncs-default-banner"></div>
            </a>
        </div>
        <div id="nav" class="ncs-nav">
            <ul>
                <li class="active">
                    <!-- <router-link to='/shop?id'=> -->
                        <a @click.prevent="go" style="cursor: pointer;"><span>店铺首页<i></i></span></a>
                    <!-- </router-link> -->
                </li>
                <li class="active">
                    <a href="#" onclick="return false"><span>全部商品<i></i></span></a>
                </li>
            </ul>
        </div>
        <div class="wrapper mt10">
            <div class="ncs-main-container">
                <div class="title">
                    <h4> 全部商品 </h4>
                </div>
                <div class="ncs-goodslist-bar">
                    <ul class="ncs-array">
                        <li>
                            <a @click.prevent="cha(0)" style="cursor: pointer;width: 60px;" :class="(curr == 0)?'asc':''">综合</a>
                        </li>
                        <li>
                            <a @click.prevent="cha(1)" style="cursor: pointer;width: 60px;" :class="(curr == 1)?'asc':''">销量</a>
                        </li>
                        <li>
                            <a @click.prevent="cha(2)" style="cursor: pointer;width: 60px;" :class="(curr == 2)?'asc':''">价格升序</a>
                        </li>
                        <li>
                            <a @click.prevent="cha(3)" style="cursor: pointer;width: 60px;" :class="(curr == 3)?'asc':''">价格降序</a>
                        </li>
                        
                        
                        <!--<li><a class="asc" href="/home/Store/goods_list/store_id/3/key/shop_price/sort/asc.html">价格</a></li>-->
                    </ul>
                    <div class="ncs-search">
                        <form id="" name="searchShop" method="get" action="">
                            <input type="hidden" name="cat_id" value="" />
                            <input type="hidden" name="store_id" value="3" />
                            <input type="text" class="text w120" name="keyword" value="" id="keyword"
                                placeholder="搜索店内商品" v-model="search" >
                            <a @click.prevent="cha(3)" style="cursor: pointer;">搜索</a>
                        </form>
                    </div>
                </div>
                <div class="content ncs-all-goods-list mb15">
                    <ul>
                        <li v-for="(item, index) in list" :key="index">
                            <router-link :to="{ path: '/goods/details', query: { productID: item.Id } }">
                                <dl>
                                        <dt><a href="#" onclick="return false" class="goods-thumb" target="_blank"><img
                                                    :src="item.ImgUrl" alt="" /></a>

                                        </dt>
                                        <dd class="goods-name"> {{item.Name}}</dd>
                                        <dd class="goods-info"><span class="price"><i>&yen;</i>￥{{ item.Price }}</span>
                                            <span class="goods-sold">已售：<strong>{{ item.Sale }}</strong> 件</span>
                                        </dd>
                                    </dl>
                            </router-link>

                        </li>

                    </ul>
                </div>
                <div class='dataTables_paginate paging_simple_numbers'>
                    <ul class='pagination'> </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            ids: 0,
            shop: [],
            list: [],
            search:'',
            curr:0
        };
    },
    activated() {
        // 如果路由传递了categoryID，则显示对应的分类商品
        if (this.$route.query.id != undefined) {
            this.ids = this.$route.query.id;
        }
        if (this.$route.query.search != undefined) {
            this.search = this.$route.query.search;
        }
        this.getData()
    },
    created() {

    },
    methods: {
        async getData() {
            var res = await this.postdata("/Shop/ShopDetail", {
                "queryId": this.ids
            });
            if (res.code == 200) {
                this.shop = res.data
            }
            res = await this.postdata("/Goods/GoodsList", {
                page: 1,
                limit: 1000,
                "ShopId": this.ids, //店铺ID
                queryName:this.search,
                "orderBy": this.curr, //排序 0-综合 1-销量倒序 2-价格正序 3-价格倒序
                "queryType": 0
            });
            this.list = res.data.items

        },
        go() {
            this.$router.push({
                path: "/shop",
                query: {
                    id: this.ids,
                },
            });
        },
        cha(index){
            this.curr = index
            this.getData()
        }
    }
};
</script>
<style scoped lang="less">
@import "../assets/css/shop.css";

.center {
    width: 1240px;
    margin: 0 auto;
}
.asc{
    font-weight: 700;
    color: #F60;
}
</style>